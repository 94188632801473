.MuiAlert-icon {
  margin-top: 4px;
  /* margin-top: -20px;
  align-items: center;
  height: 100% !important; */
}

#cancelWebAuthnAIA {
  width: "100%";
}

.select-auth-box-icon-properties {
  font-size: 1.8em;
}

.list-view-pf {
    align-items: center;
}

#secret_answer-label {
  overflow: visible;
  white-space: normal;
}

#kc-captcha {
  margin: 16px auto 0 auto;
}
